import Immutable from "immutable";
import _ from "lodash";

export default {
  getCompanyInfoCompleted(response) {
    const { extensions, modules } = response.body;

    // взять текщие extensions
    let currentExtensions = this.getIn(["company", "extensions"]);
    currentExtensions = currentExtensions ? currentExtensions.toJS() : [];

    // найти новые extensions по разнице текщих extensions с полученными extensions по code
    let newExtensions = _.differenceBy(extensions, currentExtensions, "code");
    // remove some trash from extensions
    newExtensions = newExtensions.map(item => {
      return {
        code: item.code,
        title: item.title,
        icon: item.icon,
        css: item.css,
        js: item.js
      };
    });

    // concat new extensions to already loaded
    response.body.extensions = currentExtensions.concat(newExtensions);

    // remove some trash from modules
    response.body.modules = modules.map(item => item.code);

    // set modify response.body to appState
    this.set("company", Immutable.fromJS(response.body));

    this.changed();
  },

  getCompaniesCompleted(response) {
    this.set("companies", Immutable.fromJS(response.body));
    this.changed();
  },

  getLicenseCompleted(response) {
    this.set("license", Immutable.fromJS(response.body.params || {}));
    this.set(
      "activationCode",
      Immutable.fromJS(response.body.activationCode || null)
    );
    this.changed();
  },

  getVendorCompleted(response) {
    this.set("vendor", Immutable.fromJS(response.body));
    this.changed();
  }
};
