import React from "react";
import { Form, Input, Descriptions, Button, message } from "antd";
import cn from "classnames";
import { withTranslation } from 'react-i18next';

import apiActions from "../../actions/apiActions";
import { connect } from "../StateProvider";
import { prepareDescription } from "./helpers/prepareDescription";

import styles from "./styles.less";

class ActivateForm extends React.Component {
  activateLink = "bpium.ru/activate";
  activateUrl = "https://bpium.ru/activate";

  constructor() {
    super();

    this.state = {
      description: {},
      errors: {
        email: { errors: [] },
        password: { errors: [] }
      }
    };
  }

  componentDidMount() {
    apiActions.getCompanyInfo();
    apiActions.getLicense();
  }

  handleSubmit = async (values) => {
    const { t } = this.props;
    this.props.form.validateFields((errors, data) => {
      this.setState({ errors });
      if (!errors) {
        apiActions.activate(data.license).then(response => {
          if (response.status == 200) {
            window.location.href = '/';
          } else {
            message.error(t("auth.errors.somethinWrong"));
          }
        })["catch"](err => {
          message.error(err.message);
        });
      } else {
        this.props.form.setFields({
          license: {
            errors: [new Error(t("auth.activate.wrongCode"))]
          }
        });
      }
    });
  };

  getDescriptionComponent = () => {
    const { license: params, i18n, t } = this.props;
    if (!params) return;

    const description = prepareDescription(params, i18n.language, t);

    return (
      <Descriptions.Item label="Config Info">
        <p>{description.title}:</p>
        <p>{description.users}</p>
        <p>{description.extUsers}</p>
        <p>{description.records}</p>
        <p>{description.catalogs}</p>
        <p>{description.active}</p>
      </Descriptions.Item>
    );
  };

  render() {
    const { activationCode, t } = this.props;
    const DescriptionComponent = this.getDescriptionComponent();

    return (
      <div className={styles.formContainer}>
        <div className={styles.formInner}>
          <Form className={styles.form} name="ActivateForm" onFinish={this.handleSubmit}>
            <h1>{t("auth.activate.title")}</h1>
            {DescriptionComponent}
            <Form.Item className={cn(styles.formItem)}>
              <div>
                <div className={styles.formLabel}>
                  {t("auth.activate.code")}
                </div>
                <Input
                  className={styles.formField}
                  readOnly
                  value={activationCode}
                  />
                </div>
            </Form.Item>
            <div>
              {t("auth.activate.getLicense")}{" "}
              <a href={this.activateUrl}>{this.activateLink}</a>
            </div>

            <Form.Item
              name="license"
              rules={[{ required: true, message: t("auth.fieldHintMessage")}]}
            >
              <div>
                <div className={styles.formLabel}>
                  {t("auth.activate.enterCode")}
                </div>
                  <Input.TextArea
                    className={styles.textArea}
                    rows={7}
                    autoFocus
                  />
                </div>
            </Form.Item>

            <Form.Item>
              <Button
                tabIndex={0}
                key="submit"
                type="primary"
                className={styles.loginButton}
                htmlType="submit"
              >
                {t("auth.activate.activate")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(connect(
  ActivateForm,
  { license: ["license"], activationCode: ["activationCode"] }
));
